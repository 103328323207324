import { createRouter, createWebHistory } from 'vue-router'

const landingpage = () => import('../views/landingpage.vue');
const newLandingpage = () => import('../views/newLandingpage.vue');
const dashboard = () => import('../views/dashboard.vue');
const invoice = () => import('../views/invoice.vue');
const login = () => import('../views/LoginView.vue');
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/test/:id',
            name: 'Test',
            component: () => import('../components/test.vue'),
        },
        {
            path: '/login',
            name: 'Login',
            component: login,
        },
        {
            path: '/',
            name: 'Landingpage',
            component: newLandingpage,
        },
        {
            path: '/landingpage',
            name: 'testLandingpage',
            component: landingpage,
        },
        {
            path: '/invoice-tiramana/:id',
            name: 'Invoice-Tiramana ',
            component: invoice,
        },
        {
            path: '/dashboard-master',
            name: 'MasterDashboard',
            component: dashboard,
            children: [
                {
                    path: '/rekening-edit/:id',
                    name: 'RekeningEdit',
                    component: () => import('../components/dashboard/rekeningPerusahaan/Edit.vue'),
                },
                {
                    path: '/rekening-perusahaan',
                    name: 'RekeningPerusahaan',
                    component: () => import('../components/dashboard/rekeningPerusahaan/Index.vue'),
                },
                {
                    path: '/jarak',
                    name: 'Jarak',
                    component: () => import('../components/dashboard/jarakPelabuhan/Index.vue'),

                },
                {
                    path: '/keuangan',
                    name: 'Keuangan',
                    component: () => import('../components/dashboard/keuangan/Index.vue'),
                },
                {
                    path: '/keuangan/:id',
                    name: 'KeuanganEdit',
                    component: () => import('../components/dashboard/keuangan/Edit.vue'),
                },
                {
                    path: '/User-detail/:id',
                    name: 'UserDetail',
                    component: () => import('../components/dashboard/users/Detail.vue')

                },
                {
                    path: '/Users-Management',
                    name: 'UsersManagement',
                    component: () => import('../components/dashboard/users/Index.vue')
                },
                {
                    path: '/profile',
                    name: 'Profile',
                    component: () => import('../components/dashboard/profile/Profile.vue')
                },
                {
                    path: '/add-pembayaran',
                    name: 'AddPembayaran',
                    component: () => import('../components/dashboard/spal/CreatePembayaran.vue'),
                },
                {
                    path: '/dashboard-landingpage',
                    name: 'DashboardLandingpage',
                    component: () => import('../components/dashboard/landingpage/DashboardLandingpage.vue'),
                },
                {
                    path: '/jenis-pembayaran-spal',
                    name: 'JenisPembayaran',
                    component: () => import('../components/dashboard/spal/Jenispembayaran.vue'),
                },
                {
                    path: '/edit-pembayaran-spal/:id',
                    name: 'EditPembayaran',
                    component: () => import('../components/dashboard/spal/Editpembayaran.vue'),
                },
                {
                    path: '/muatan',
                    name: 'Muatan',
                    component: () => import('../components/dashboard/muatan/Muatan.vue'),
                },
                {
                    path: '/muatan/:id',
                    name: 'MuatanEdit',
                    component: () => import('../components/dashboard/muatan/Edit.vue'),
                },
                {
                    path: '/file-manager',
                    name: 'FileManager',
                    component: () => import('../components/dashboard/fileManager/fileManager.vue')
                },
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    component: () => import('../components/dashboard/Dashboard.vue'),
                },
                {
                    path: '/pesan',
                    component: () => import('../components/dashboard/Pesan.vue'),
                    name: 'Pesan'
                },
                {
                    path: '/detail-pesan/:id',
                    component: () => import('../components/dashboard/pesan/Detail.vue'),
                    name: 'DetailPesan'
                },

                {
                    path: '/invoice',
                    component: () => import('../components/dashboard/invoice/Invoice.vue'),
                    name: 'Invoice'
                },
                {
                    path: '/shippers',
                    component: () => import('../components/dashboard/shipper/Shipper.vue'),
                    name: 'Shipper'
                },
                {
                    path: '/shipper-edit/:id',
                    component: () => import('../components/dashboard/shipper/Edit.vue'),
                    name: 'EditShipper'
                },
                {
                    path: '/kapals',
                    component: () => import('../components/dashboard/kapal/Kapal.vue'),
                    name: 'Kapal'
                },
                {
                    path: '/kapal-edit/:id',
                    component: () => import('../components/dashboard/kapal/Edit.vue'),
                    name: 'KapalEdit'
                },
                {
                    path: '/order',
                    component: () => import('../components/dashboard/order/Order.vue'),
                    name: 'Order'
                },
                {
                    path: '/order-detail/:id',
                    component: () => import('../components/dashboard/order/OrderDetail.vue'),
                    name: 'OrderDetail',
                    children: [
                        {
                            path: '/spal-detail/:id',
                            component: () => import('../components/dashboard/spal/SpalDetail.vue'),
                            name: 'SpalDetail'
                        },
                        {
                            path: '/invoice-order/:id',
                            component: () => import('../components/dashboard/invoice/InvoiceOrder.vue'),
                            name: 'InvoiceOrder',
                        },
                        {
                            path: '/invoice-create/:id',
                            component: () => import('../components/dashboard/invoice/InvoiceCreate.vue'),
                            name: 'InvoiceCreate'
                        },
                        {
                            path: '/invoice-edit/:id',
                            component: () => import('../components/dashboard/invoice/InvoiceEdit.vue'),
                            name: 'InvoiceEdit'
                        },
                        {
                            path: '/timeline-order/:id',
                            component: () => import('../components/dashboard/TimelineOrder.vue'),
                            name: 'TimelineOrder'
                        }
                    ]
                },
                {
                    path: '/order-detail/create-spal/:id',
                    component: () => import('../components/dashboard/spal/CreateSpal.vue'),
                    name: 'CreateSpal'
                },
                {
                    path: '/order-detail/create-syarat/:id',
                    component: () => import('../components/dashboard/spal/SyaratCreate.vue'),
                    name: 'CreateSyarat'
                },
                {
                    path: '/spal-print/:id',
                    component: () => import('../components/dashboard/spal/SpalPrint.vue'),
                    name: 'SpalPrint'
                },
                {
                    path: '/pelabuhan',
                    component: () => import('../components/dashboard/pelabuhan/Pelabuhan.vue'),
                    name: 'Pelabuhan'
                },
                {
                    path: '/pelabuhan-edit/:id',
                    component: () => import('../components/dashboard/pelabuhan/Edit.vue'),
                    name: 'PelabuhanEdit'
                },
                {
                    path: '/perusahaan',
                    component: () => import('../components/dashboard/perusahaan/Perusahaan.vue'),
                    name: 'Perusahaan'
                },
                {
                    path: '/perusahaan-edit/:id',
                    component: () => import('../components/dashboard/perusahaan/Edit.vue'),
                    name: 'PerusahaanEdit'
                },
                {
                    path: '/calculator',
                    component: () => import('../components/dashboard/calculator/Index.vue'),
                    name: 'Calculator'
                }



            ]
        },
    ]
});

// const router = createRouter({
//     history: createWebHistory(),
//     routes,
//     linkActiveClass: 'active',
// });

router.beforeEach((to, from, next) => {
    document.title = 'Tiramana' + ' | ' + to.name;

    // add from
    // if(to.matched.some(record => record.meta.requiresAuth)){
    //     if(!store.getters.isAuthenticated){
    //         next({
    //             path: '/login',
    //             params: { nextUrl: to.fullPath }
    //         })
    //     }else{
    //         next()
    //     }
    // }else if(to.matched.some(record => record.meta.requiresGuest)){
    //     if(store.getters.isAuthenticated){
    //         next({
    //             path: '/dashboard',
    //             params: { nextUrl: to.fullPath }
    //         })
    //     }else{
    //         next()
    //     }
    // }else{
    //     next()
    // }




    const isAuthenticated = JSON.parse(localStorage.getItem('Authenticated'));
    if (to.name !== 'Landingpage' && to.name !== 'Login' && !isAuthenticated) 
    // next({ name: 'Invoice-Tiramana' });
    next({ name: 'Landingpage' });
    // if(to.name ==='Login' && isAuthenticated)next({name:'Landingpage'});
    else next();


});
export default router;







