
<template>
  <div id="app">

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  }
</script>
<style>
#apps{
  width: 100%;
}
</style>
